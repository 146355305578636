import React from "react"
const SmartLaundryAppLinkPage = () => {
    if (typeof navigator !== 'undefined') {
        const ua = navigator.userAgent;
        if (ua.search(/iPhone/) != -1 || ua.search(/iPad/) != -1 || ua.search(/iPod/) != -1) {
            window.location.href = 'https://apps.apple.com/jp/app/id1313142194?mt=8';
        } else if (ua.search(/Android/) != -1) {
            window.location.href = 'https://play.google.com/store/apps/details?id=jp.smart_laundry';
        }
    }
    return (
        <div className="appLink">
            <div className="appLink__inner">
                <div className="appLink__innerHeader">
                    <img
                        width={100}
                        src="https://balukoweb.blob.core.windows.net/images/smartlaundry/sl01.png"
                        alt="アプリアイコン"
                    />
                </div>
                <div className="appLink__innerBody">
                    <p className="appLink__note">自動でApp Store / Google Play が起動しない場合は下のボタンをクリックしてください。</p>

                    <div className="appLink__btnGroup">
                        <a href="https://apps.apple.com/jp/app/id1313142194?mt=8">
                            <img
                                width={107}
                                src="https://balukoapplp.blob.core.windows.net/images/icon/app_store_applink.png"
                                alt="App Store"
                            />
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=jp.smart_laundry">
                            <img
                                width={134}
                                className="appLink__icon__right"
                                src="https://balukoapplp.blob.core.windows.net/images/icon/google_play_store_applink.png"
                                alt="google play store"
                            />
                        </a>
                    </div>
                </div>
                <p className="appLink__info">
                    Appleのロゴは、米国もしくはその他の国や地域におけるApple Inc.の商標です。App Storeは、Apple Inc.のサービスマークです。Google Play および Google Play ロゴは、Google LLC の商標です。
                </p>
            </div>
        </div>
    )
}

export default SmartLaundryAppLinkPage

